.footer--pin {
  margin-top: 1 rem;
   padding: 1 rem;
   position: fixed;
   bottom: 0;
   left: 0;
   width: 100% ;
}
a {
  color: gray;
}
a:hover {
  color: darkgray;
  text-decoration: none;
}
.center {
  text-align: center;
}